.progress {
  width: 150px;
  height: 150px;
  background: none;
  position: relative;
}

.progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar-drop {
  display: none !important;
}

/* .sidebar-drop:hover {
  display: flex !important;
  flex-direction: column !important;
} */

.sidebar-drop-toggle.toggled > .sidebar-drop {
  display: flex !important;
  flex-direction: column !important;
}

.notifications-container {
  width: 300px;
  height: 400px;
  background-color: #fff;
  position: absolute;
  top: 3.5rem;
  right: -1rem;
  border-radius: 1rem;
  z-index: 99999999999;
}

.notifications-container::after {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  color: #fff;
  position: absolute;
  top: -10px;
  right: 32px;
  transform: rotate(45deg);
}