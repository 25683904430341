.bg-g {
    /* background: #f7d4d4 !important; */
    -webkit-box-shadow: inset 0px 0px 15px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: inset 0px 0px 15px 0px rgba(0,0,0,0.5);
    box-shadow: inset 0px 0px 15px 0px rgba(0,0,0,0.5);
}

.bg-d {
    background: #C9E4D1 !important;
    /* -webkit-box-shadow: inset 0px 0px 15px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: inset 0px 0px 15px 0px rgba(0,0,0,0.5);
    box-shadow: inset 0px 0px 15px 0px rgba(0,0,0,0.5); */
}