.vc-container {
  padding-left: 18px;
  padding-right: 5px;
  padding-top: 10px;
  position: relative;
}

.vc-container::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 7px;
  height: 90%;
  background-color: var(--bs-primary);
  border-radius: 10px;
}

.vc-p {
  font-size: .9em;
  margin-top: 5px;
  font-weight: bold;
}

.vc-small {
  font-size: .7em;
}