.grow_hover .more-btn {
    position: absolute;
    right: 10px;
    bottom: 0;
    width: 20px;
}

.treatments_card_container {
    position: relative;
    z-index: 2;
}


.treatments_card_container .treatments_card_subcard {
    display: none;
}

.treatments_card_container.opened .treatments_card_subcard {
    display: flex;
    width: 100%;
    background-color: #444;
    border: 1px solid red;
    position: relative;
    top: -10px;
    height: 75px;
    z-index: 1;
}

.x_button:hover{
    cursor: pointer;
    color: #444;
}

.trash:hover{
    color:red;
}

.checkbox_input {
    visibility: visible !important;
  }
  
.edit:hover{
    color:green;
}

.dropdown_buttons{
    z-index: 9999;
}

.treatment_button{
    border-radius: 20px 0px 20px 0px;
    width:15%;
    opacity: 0.5;
}
.treatment_button_active{
    border-radius: 20px 0px 20px 0px;
    width:15%;
    opacity: 1;
}



.step_active{
    background-color: #001B70;
    color:#fff;
    width:12%;
    border:none;
    font-weight: bold;
    outline:none !important; 
}

.step_inactive{
    background-color: #ccc;
    color:#001B70;
    width:12%;
    border:none;
    font-weight: bold;
    outline:none !important; 
}

.input_button:hover{
    color:#444;
    cursor: pointer;
}
