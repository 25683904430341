.ul-sc {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    list-style-type: none;
    position: relative;
    margin-top: 5em;
}

.ul-sc li {
    width: 33.3%;
    height: 25px;
    background-color: gray;
    border-radius: 20px;
    margin-right: -20px;
    margin-left: -20px;
}

.ul-sc .li-gold {
    background-color: #DDB802;
}

.ul-sc .li-gold::after {
    content: "5";
    position: absolute;
    top: -25px;
    left: 4%;
    font-size: 1.3em;
    font-weight: bold;
    color: #444;
}
.ul-sc .li-gold::before {
    content: "Gold";
    position: absolute;
    bottom: -25px;
    left: 4%;
    font-size: 1.3em;
    font-weight: bold;
    color: #444;
}

.ul-sc .li-platinum {
    background-color: #C9D6DD;
}

.ul-sc .li-platinum::after {
    content: "20";
    position: absolute;
    top: -25px;
    left: 35%;
    font-size: 1.3em;
    font-weight: bold;
    color: #444;
}
.ul-sc .li-platinum::before {
    content: "Platinum";
    position: absolute;
    bottom: -25px;
    left: 35%;
    font-size: 1.3em;
    font-weight: bold;
    color: #444;
}

.ul-sc .li-black {
    background-color: #000000;
}

.ul-sc .li-black::after {
    content: "+35";
    position: absolute;
    top: -25px;
    left: 65%;
    font-size: 1.3em;
    font-weight: bold;
    color: #444;
}
.ul-sc .li-black::before {
    content: "Black";
    position: absolute;
    bottom: -25px;
    left: 65%;
    font-size: 1.3em;
    font-weight: bold;
    color: #444;
}

.sc-table {
    width: 100%;
}

.sc-table th {
    font-size: 1.4em;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.sc-table td {
    margin-top: 10px;
    font-size: 1.2em;
    padding: 10px;
}