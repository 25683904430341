.home-bg1 {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    /* background: rgb(0,21,92);
background: linear-gradient(90deg, rgba(0,21,92,1) 0%, rgba(9,9,121,1) 50%, rgba(0,212,255,1) 100%); */
}

.patientcard-bar {
    position: absolute;
    width: 5px;
    height: 75% !important;
    border: none;
    border-radius: 20px;
}

.patientcard-bar.type-1 {
    background-color: #10456D;
}
.patientcard-bar.type-2 {
    background-color: #288B7E;
}
.patientcard-bar.type-3 {
    background-color: #5A468E;
}
.patientcard-bar.type-4 {
    background-color: #E0614A;
}
.patientcard-bar.type-5 {
    background-color: #E8793A;
}

.patientcard-bar.type-6 {
    background-color: #F7C05B;
}

