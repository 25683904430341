body, #root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  position: relative;
}

* {
  margin: 0;
  font-family: 'Lato', sans-serif;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
}
/* 
*::-webkit-scrollbar {
  display: none;
} */

/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00155C;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00155C;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#content {
  max-width: 100vw!important;
  padding: 0;
}

input.form-control, select.form-control {
  border: none;
  border-bottom: 2px solid #0027a8;
  margin-bottom: 1rem;
  height: 35px;
  border-radius: 0!important;
}

label {
  margin-bottom: 5px;
  font-weight: 500;
}

.btn:focus {
  outline: none !important;
  box-shadow: none!important;
}

textarea {
  max-width: 100%;
  max-height: 100%;
  border-bottom: 2px solid #0027a8!important;
  padding-left: 5px;
  padding-right: 5px;
}

.clinic-history-button.type-1:hover {
  width: 145px;
  -webkit-transition: width .3s;
  transition: width .3s;
  color: #fff;
  background-color: #10456D;
  font-weight: bold;
}
.clinic-history-button.type-1:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.clinic-history-button.type-1 {
  background-color: #10456D!important;
  color: #10456D!important;
  height: 85%;
  max-height: 100px;
  width: 10px;
}

.clinic-history-button.type-2:hover {
  width: 145px;
  -webkit-transition: width .3s;
  transition: width .3s;
  color: #fff;
  background-color: #288B7E;
  font-weight: bold;
}
.clinic-history-button.type-2:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.clinic-history-button.type-2 {
  background-color: #288B7E!important;
  color: #288B7E!important;
}

.clinic-history-button.type-3:hover {
  width: 145px;
  -webkit-transition: width .3s;
  transition: width .3s;
  color: #fff;
  background-color: #5A468E;
  font-weight: bold;
}
.clinic-history-button.type-3:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.clinic-history-button.type-3 {
  background-color: #5A468E!important;
  color: #5A468E!important;
}

.clinic-history-button.type-4:hover {
  width: 145px;
  -webkit-transition: width .3s;
  transition: width .3s;
  color: #fff;
  background-color: #E0614A;
  font-weight: bold;
}
.clinic-history-button.type-4:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.clinic-history-button.type-4 {
  background-color: #E0614A!important;
  color: #E0614A!important;
}

.clinic-history-button.type-5:hover {
  width: 145px;
  -webkit-transition: width .3s;
  transition: width .3s;
  color: #fff;
  background-color: #E8793A;
  font-weight: bold;
}
.clinic-history-button.type-5:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.clinic-history-button.type-5 {
  background-color: #E8793A!important;
  color: #E8793A!important;
}

.clinic-history-button.type-6:hover {
  width: 145px;
  -webkit-transition: width .3s;
  transition: width .3s;
  color: #fff;
  background-color: #F7C05B;
  font-weight: bold;
}
.clinic-history-button.type-6:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}
.clinic-history-button.type-6 {
  background-color: #F7C05B!important;
  color: #F7C05B!important;
}

textarea {
  resize: none!important;
}


#sidebar::-webkit-scrollbar {
  display: none!important;
}

#sidebar {
  -ms-overflow-style: none!important;  /* IE and Edge */
  scrollbar-width: none!important;  /* Firefox */
}

.shadow {
  background-color: #fff;
}

.dropdown-toggle::after {
  content: none;
}








#instructiveModal {
  background-color: rgba(0, 0, 0, 0.3);
}

#modal-style {
  position: absolute !important;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -30%);
  border-radius: 20px !important;
}

#modal-style button.btn-modal {
  border-radius: 10px;
}

ol.carousel-indicators {
  position: absolute !important;
  bottom: -30px;
}

.carousel-indicators li {
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
  margin: 5px !important;
}

.carousel-indicators li.active {
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 10px !important;
}

.modal-close-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-top: 20px;
  padding-right: 30px;
}

/* @media screen and (min-width: 768px) {
  .safari-modal {
    display: none !important;
  }
} */

.safari-modal {
  display: block !important;
  position: fixed !important;
  z-index: 9999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  background-color: rgba(0, 0, 0, 0.3);
}

.safari-modal .container {
  background-color: white;
  border-radius: 20px !important;
  padding: 15px;
  margin: auto;
  max-width: 75vw;
  max-height: 90vh;
  width: 100%;
}

.safari-modal .bg-white {
  background-color: white;
  border-radius: 20px;
  position: relative;
  max-width: 100%;
  width: 100%;
  /* overflow: hidden; */
}

.safari-modal img {
  /* max-width: 60%; */
  /* height: auto; */
  margin: auto;
}

.modal-close-container {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {
  #modal-style {
    width: 75vw;
    max-height: 90vh;
    margin: auto;
    margin-top: 60px;
  }

  #modal-style img,
  #modal-style picture {
    width: 100%;
    height: auto;
  }

  .safari-modal {
    display: block !important;
    position: fixed !important;
    z-index: 9999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .safari-modal .container {
    background-color: white;
    border-radius: 20px !important;
    padding: 15px;
    max-height: 95vh;
    margin: auto;
  }

  .safari-modal .bg-white {
    background-color: white;
    padding: 15px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
  }

  .safari-modal img {
    max-width: 60%;
    height: auto;
    margin: auto;
  }

  .modal-close-container {
    padding-top: 10px;
  }
}

button.clinic-history-button {
  height: 85%;
  max-height: 100px;
  width: 10px;
  overflow: hidden;
  -webkit-transition: width .2s;
  transition: width .2s;
  white-space: nowrap;
  background-color: #00155c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #00155c;
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  padding: 0;
}
button.clinic-history-button:hover {
  width: 145px;
  -webkit-transition: width .3s;
  transition: width .3s;
  color: #fff;
  background-color: #00155c;
  font-weight: bold;
  padding: 5px;
}
button.clinic-history-button:hover::after {
  content: 'Ver historia clinica';
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

iframe {
  width: 100%;
  height: 600px;
}

/* SCICONS */
.sc-beginner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background-color: lightblue;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-beginner::after {
  content: 'B';
  font-weight: bolder;
}

.sc-silver {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background-color: darkgray;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-silver::after {
  content: 'S';
  font-weight: bolder;
}

.sc-gold {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background-color: #FFD700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-gold::after {
  content: 'G';
  font-weight: bolder;
}

.sc-platinum {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-platinum::after {
  content: 'P';
  font-weight: bolder;
}

.sc-black {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-black::after {
  content: 'B';
  font-weight: bolder;
}
/* SCICONS */

ul.ul-primary {
  list-style: none; /* Remove default bullets */
}

ul.ul-primary li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #00155c; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.search-input {
  padding-left: 15px;
  position: relative;
  width: 240px !important;
  padding-left: 12px 10px !important;
}

.search-input::before {
  content: '\f109';
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
}

.remove-scroll::-webkit-scrollbar {
  display: none;
}

.remove-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color:darkred;
  display: inline-block;
  margin-left: 4px;
}